@font-face {
  font-family: 'Aktiv Grotesk';
  src: url('assets/fonts/AktivGrotesk-Medium.woff2') format('woff2'),
    url('assets/fonts/AktivGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aktiv Grotesk';
  src: url('assets/fonts/AktivGrotesk-Light.woff2') format('woff2'),
    url('assets/fonts/AktivGrotesk-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Amoret Sans';
  src: url('assets/fonts/AmoretSans.woff2') format('woff2'),
    url('assets/fonts/AmoretSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('assets/fonts/Inter-VariableFont.ttf') format('woff2');
  font-style: normal;
  font-display: swap;
}
