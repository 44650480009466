* {
  margin: 0;
  text-decoration-line: none;
  text-decoration-skip-ink: none;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 0.1em;
}

a {
  color: currentColor;
}

html,
body {
  overflow-x: hidden;
}

html,
body {
  background-color: transparent;
}

body {
  position: relative;
  font-family: var(--font-family-body);
  color: var(--color-text-dark);
  font-size: 16px;
  line-height: 1.45;
}

@media (min-width: 768px) {
  body {
    line-height: 1.6;
  }
}

button {
  cursor: pointer;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  margin: -1px !important; /* Fix for https://github.com/twbs/bootstrap/issues/25686 */
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
}

iframe {
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

video,
img {
  max-width: 100%;
  height: auto;
}
