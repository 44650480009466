@value md-up from 'assets/css/variables/breakpoints.css';

.title {
  text-transform: uppercase;
  line-height: 1.25;
  font-family: var(--font-family-title);
}

.titleSuper {
  composes: title;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.45;
}

@media (md-up) {
  .titleSuper {
    font-size: 2rem;
    line-height: 1.55;
  }
}

.titleExtraSuper {
  composes: titleSuper;
  font-size: 3rem;
}

@media (md-up) {
  .titleExtraSuper {
    font-size: 5rem;
    line-height: 1.55;
  }
}

.titleContentPrimary {
  composes: title;
  font-size: 0.9rem;
  line-height: 2;
}

@media (md-up) {
  .titleContentPrimary {
    font-size: 1.25rem;
  }
}

.titleContentPrimaryBigger {
  composes: title;
  font-size: 1rem;
  line-height: 1.5;
}

@media (md-up) {
  .titleContentPrimaryBigger {
    font-size: 1.5rem;
  }
}

.subtitleBig {
  font-family: var(--font-family-body);
  font-size: 1rem;
  line-height: 1.25;
}

@media (md-up) {
  .subtitleBig {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

.subtitleMedium {
  font-family: var(--font-family-body);
  font-size: 1rem;
  line-height: 1.25;
}

@media (md-up) {
  .subtitleMedium {
    font-size: 1.125rem;
    line-height: 1.5;
  }
}
