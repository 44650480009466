@value md-up, md-down, lg-up, lg-down, xl-up, xxl-up from 'assets/css/variables/breakpoints.css';

.mainMenu {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding: 1.5rem;
  background-color: var(--color-new-off-white);
  font-family: var(--font-family-new);
}

@media (md-up) {
  .mainMenu {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (xl-up) {
  .mainMenu {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

@media (md-down) {
  .mainMenuFixed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-new-off-white);
    z-index: 100;
  }
}

.mainMenuInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
}

@media (md-down) {
  .mainMenuFixed .mainMenuInner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

@media (md-up) {
  .mainMenuInner {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

@media (md-down) {
  .logoContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.logo {
  height: 38px;
}

@media (md-up) {
  .logo {
    height: 47px;
  }
}

@media (lg-up) {
  .logo {
    height: 56px;
  }
}

/* @media (xxl-up) {
  .logo {
    font-size: 1.7rem;
  }
} */

.logoImg {
  width: 100%;
  height: 100%;
}

.button {
  border: 0;
  /* color: var(--color-brown); */
  background-color: var(--color-white);
  border-radius: 50%;
  height: 2.75rem;
  width: 2.75rem;
}

.icon {
  margin: auto;
  width: 1.25rem;
  height: 1.25rem;
}

.menuButton {
  composes: button;
}

.menuButtonHide {
  display: none;
}

@media (md-up) {
  .menuButton {
    display: none;
  }
}

.closeButton {
  composes: button;
}

@media (md-up) {
  .closeButton {
    display: none;
  }
}

.closeIcon,
.menuIcon {
  composes: icon;
}

.closeButtonHide {
  display: none;
}

.navLinksWrapper {
  composes: flexGap from 'assets/css/elements/flexGap.css';
}

@media (md-down) {
  .navLinksWrapper:not(.navLinksWrapperShow) {
    display: none;
  }
}

@media (md-down) {
  .navLinksWrapper {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    --gap: 2rem;
  }
}

@media (md-up) {
  .navLinksWrapper {
    align-items: center;
    --gap: 0;
    height: auto;
  }
}

.navItems {
  display: flex;
  /* margin: calc(var(--gap) / -2); */
  list-style: none;
  padding-left: 0;
  align-items: flex-end;
  --gap: 0.5rem;
}

.navItems > * {
  margin: var(--gap);
}

@media (md-up) {
  .navItems {
    --gap: 0.65rem;
  }
}

@media (xxl-up) {
  .navItems {
    --gap: 0.3125rem;
  }
}

@media (md-down) {
  .navItems {
    flex-direction: column;
    text-align: center;
    align-items: initial;
  }
}

.navbarLinkItem {
  font-size: 1.15rem;
  line-height: 1;
}

@media (md-up) {
  .navbarLinkItem {
    font-size: 0.8rem;
  }
}

@media (lg-up) {
  .navbarLinkItem {
    font-size: 0.9rem;
  }
}

@media (xl-up) {
  .navbarLinkItem {
    font-size: 1rem;
  }
}

@media (xxl-up) {
  .navbarLinkItem {
    font-size: 1.15rem;
  }
}

.navbarLinkItemLink {
  composes: button from 'assets/css/elements/newButtons.css';
}

.navbarLinkItemLinkSignUp {
  composes: buttonFilled from 'assets/css/elements/newButtons.css';
}

/* @media (xl-up) {
  .navbarLinkItemLinkSignUp {
    padding: 0.45rem 1.45rem;
  }
} */

@media (md-up) {
  .socialLinksWrapper {
    margin-left: 1.25rem;
  }
}

@media (lg-up) {
  .socialLinksWrapper {
    margin-left: 1.5rem;
  }
}

@media (xl-up) {
  .socialLinksWrapper {
    margin-left: 1.625rem;
  }
}

.socialLinks {
  display: flex;
  list-style: none;
  padding: 0;
  --gap: 1rem;
  margin: calc(var(--gap) / -2);
}

.socialLinks > * {
  margin: var(--gap);
}

.socialIcon {
  width: 1.4rem;
}

/* @media (md-up) {
  .socialIcon {
    width: 1.1rem;
  }
} */

@media (lg-up) {
  .socialIcon {
    width: 1.4rem;
  }
}
