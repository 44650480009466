@value md-up from 'assets/css/variables/breakpoints.css';

:root {
  --content-max-width: 1440px;
  --content-max-width-small: 920px;

  --page-gutter: 1rem;
  --section-gap: 4.5rem;
  --section-gap-big: calc(var(--section-gap) * 1.5);
  --section-gap-small: calc(var(--section-gap) * 0.5);

  --space-xxxs: 0.25rem;
  --space-xxs: 0.5rem;
  --space-xs: 0.75rem;
  --space-sm: 0.65rem;
  --space-md: 0.75rem;
  --space-lg: 1rem;
  --space-xl: 1.5rem;
  --space-xxl: 2rem;
  --space-xxxl: 3.5rem;
}

@media (min-width: 768px) {
  :root {
    --section-gap: 3.5rem;

    --space-xxxs: 0.25rem;
    --space-xxs: 0.5rem;
    --space-xs: 0.75rem;
    --space-sm: 1rem;
    --space-md: 1.5rem;
    --space-lg: 2rem;
    --space-xl: 3rem;
    --space-xxl: 4rem;
    --space-xxxl: 7rem;
  }
}

@media (min-width: 975px) {
  :root {
    --section-gap: 4rem;
  }
}
