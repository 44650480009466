@value sm-up, md-up from 'assets/css/variables/breakpoints.css';

.container {
  composes: contentContainer from 'assets/css/elements/layout.css';
  /* margin-top: var(--section-gap);
  margin-bottom: var(--section-gap); */
  height: calc(100vh - var(--header-height));
  --header-height: 92px;
  color: var(--color-new-off-black);
  background-color: var(--color-new-background);
  font-family: var(--font-family-new);
}

@media (md-up) {
  .container {
    --header-height: 104px;
  }
}

.containerInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  display: block;
  height: 38px;
  margin-block: 2.75rem 2rem;
}

@media (md-up) {
  .containerInner {
    justify-content: center;
  }

  .logo {
    display: none;
  }
}

.dialog {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--color-white);
  outline: 1px solid var(--color-new-grey);
  border-radius: 3rem 3rem 0 0;
  padding: 40px 24px;
  width: 100%;
  max-width: 840px;
  height: 100%;
}

@media (md-up) {
  .dialog {
    gap: 3.5rem;
    padding: 64px 110px;
    border-radius: 3rem;
    height: auto;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
}

.description {
  font-size: 1rem;
  font-weight: 300;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 300px; */
  align-items: center;
  row-gap: 0.5rem;
  column-gap: 1.5rem;
}

@media (sm-up) {
  .buttons {
    flex-direction: row;
  }
}

.button {
  composes: button from 'assets/css/elements/newButtons.css';
  text-align: center;
  width: 100%;
}

@media (md-up) {
  .button {
    max-width: 298px;
  }
}

.buttonSecondary {
  composes: buttonFilled from 'assets/css/elements/newButtons.css';
}

/* @media (sm-up) {
  .button {
    width: auto;
  }
} */

/* .button:first-child {
  margin-bottom: 2rem;
} */

/* @media (sm-up) {
  .button:first-child {
    margin-right: 2rem;
    margin-bottom: 0;
  }
} */
