@value md-up from 'assets/css/variables/breakpoints.css';

.wrapper {
  position: relative;
}

.video {
  position: relative;
  object-fit: cover;
  /* height: calc(100vh - var(--header-height)); */
  aspect-ratio: 1000 / 540;
  --header-height: 92px;
  z-index: 2;
}
.video > img {
  min-width: 100%;
}

@media (md-up) {
  .video {
    aspect-ratio: 1000 / 540;
    --header-height: 104px;
  }
}

.thumbnail {
  composes: video;
  z-index: 1;
  position: absolute;
  width: 100%;
}
