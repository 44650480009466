.contentContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: var(--page-gutter);
  padding-left: var(--page-gutter);
  position: relative;
  z-index: 1;
}

.contentContainerVertical {
  margin-top: var(--section-gap);
  margin-bottom: var(--section-gap);
}

.contentContainerInner {
  width: 100%;
  max-width: var(--content-max-width);
  margin-right: auto;
  margin-left: auto;
}

.contentContainerInnerSmall {
  composes: contentContainerInner;
  max-width: var(--content-max-width-small);
}
