:root {
  /* Blacks and whites */
  --color-black: #000;
  --color-off-black: #373f41;
  --color-white: #fff;

  /* Brand/site colours */
  --color-brown: #5f1e19;
  --color-pink-light: #f08367;
  --color-pink-burnt-sienna: #ef8466;
  --color-pink-extra-extra-light: #f4eeee;
  --color-pink-extra-light: #fce9e4;
  --color-brown-transparent: rgba(95, 30, 25, 0.5);
  --color-footer-copyright: #d6c5c3;
  --color-dark-blue: #0d2043;
  --color-light-light-blue: #dbe3e8;

  /* New colours */
  --color-new-background: #fbfbfb;
  --color-new-off-white: #f5f5f5;
  --color-new-off-black: #303030;
  --color-new-green: #093a18;
  --color-new-pink: #ffbaf0;
  --color-new-grey: #efefef;
  --color-new-footer-copyright: #8e8e97;

  /* Text */
  --color-text-dark: var(--color-black);
  --color-text-light: var(--color-white);

  /* Backgrounds */
  --color-bg-primary: var(--color-white);
  --color-bg-secondary: var(--color-blue-cotton);
}
