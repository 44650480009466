.button {
  display: block;
  color: var(--color-new-green);
  padding-inline: 1.33rem;
  padding-block: 0.75rem;
  background-color: var(--color-white);
  border-radius: 50px;
  text-decoration: none;
  border: 1px solid currentColor;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
}

.buttonFilled {
  border-color: var(--color-new-pink);
  background-color: var(--color-new-pink);
}
