@value md-up from 'assets/css/variables/breakpoints.css';

:root {
  /* Sizes */
  --text-xs: 0.75rem;
  --text-sm: 0.875rem;
  --text-md: 1rem;
  --text-lg: 1.15rem;
  --text-xl: 1.5rem;
  --text-xl-plus: 2rem;
  --text-xxl: 2.25rem;

  /* Font families */
  --font-family-body: 'Aktiv Grotesk';
  --font-family-title: 'Amoret Sans';
  --font-family-new: 'Inter', sans-serif;
}

@media (min-width: 768px) {
  :root {
    --text-xs: 0.75rem;
    --text-sm: 0.875rem;
    --text-md: 1rem;
    --text-lg: 1.15rem;
    --text-xl: 1.5rem;
    --text-xl-plus: 2rem;
    --text-xxl: 2.25rem;
  }
}
