@value md-down, md-up, lg-up, lg-down from 'assets/css/variables/breakpoints.css';

.footer {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xl);
  background: linear-gradient(90.03deg, #d7d7d733 0%, #d7d7d700 100%)
    var(--color-new-off-white);
  color: var(--color-new-off-black);
  font-family: var(--font-family-new);
  font-size: 0.95rem;
}

@media (md-up) {
  .footer {
    background: radial-gradient(
        50% 100% at 50% 50%,
        #d7d7d733 0%,
        #d7d7d700 100%
      )
      var(--color-new-off-white);
  }
}

.footerInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  /* max-width: 1150px; */
}

.title {
  composes: title from 'assets/css/elements/title.css';
  font-size: 1.5rem;
  margin-bottom: var(--space-xl);
  text-transform: none;
}

@media (xl-up) {
  .title {
    font-size: 2.5rem;
  }
}
/* Footer */

.subtitle {
  composes: title from 'assets/css/elements/title.css';
  font-family: var(--font-family-new);
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  text-transform: none;
}

@media (md-up) {
  .subtitle {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

/* @media (xl-up) {
  .subtitle {
    font-size: 1.4rem;
    margin-bottom: 1.8rem;
  }
} */

.textH4 {
  font-weight: 500;
  /* text-transform: uppercase; */
  line-height: 1.4625rem;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
}

@media (md-up) {
  .textH4 {
    font-size: 1.125rem;
  }
}

.footerContent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.footerContent a {
  transition: all 0.2s ease-in-out;
}

.footerSection {
  min-width: calc((100% / 3) - 1.5rem);
}

.footerNav {
  /* min-width: 25%; */
}

.footerAddresses {
  /* min-width: 50%; */
}

.footerAddressesColumns {
  composes: flexGap from 'assets/css/elements/flexGap.css';
  --gap: 2.5rem;
}

.footerAddressesColumns > div {
  width: fit-content;
  max-width: 50%;
  font-size: 0.8rem;
  line-height: 1.2rem;
}

@media (md-up) {
  .footerAddressesColumns > div {
    width: 50%;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (xl-up) {
  .footerAddressesColumns > div {
    font-size: 1.15rem;
    line-height: 1.75rem;
  }
}

.footerAddressesColumns > div:nth-child(2) {
  flex-grow: 1;
}

.footerAddressesColumns > div > p {
  font-size: 0.875rem;
  font-weight: 300;
}

@media (md-up) {
  .footerAddressesColumns > div > p {
    font-size: 1.125rem;
  }
}

.footerContact {
  /* min-width: 25%; */
  font-size: 0.875rem;
}

@media (md-up) {
  .footerContact {
    font-size: 1rem;
  }
}

/* .footerContact a {
  color: var(--color-pink-light);
} */

@media (md-down) {
  /* .footerNav {
    display: none;
  } */
  .footerContent {
    flex-direction: column;
  }
  .footerAddresses {
    width: 100%;
  }
  .footerContact {
    /* margin-top: var(--space-xl); */
    width: 100%;
  }
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: var(--space-xl);
  margin-bottom: var(--space-md);
  font-size: 0.75rem;
}

@media (md-up) {
  .bottomContainer {
    font-size: 0.85rem;
  }
}

.copyright {
  color: var(--color-new-footer-copyright);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.5rem;
}

@media (md-down) {
  .copyrightYear {
    display: block;
  }
}

.legalLinks {
  display: flex;
  flex-direction: column;
}

@media (md-up) {
  .copyright {
    flex-direction: row;
  }
  .legalLinks {
    flex-direction: row;
    gap: 1rem;
  }
}

.copyright a {
  font-weight: 400;
  display: inline-block;
  text-decoration: underline;
}

@media (md-down) {
  .copyright a:nth-of-type(1) {
    margin-left: 0;
  }
}

.copyright a:hover {
  text-decoration: none;
}

.bottomNav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.625rem;
  /* text-transform: uppercase; */
  /* font-size: 1.15rem;
  letter-spacing: 0.1em;
  line-height: 2.2; */
}

/* .bottomNav a:hover {
  color: var(--color-pink-light);
}

.bottomNavisActive {
  color: var(--color-pink-light);
} */

.navbarLinkItem {
  display: flex;
  justify-content: center;
}

@media (md-up) {
  .navbarLinkItem {
    justify-content: flex-start;
  }
}

.navbarLinkItemLink {
  composes: button from 'assets/css/elements/newButtons.css';
  width: 100%;
  max-width: 430px;
}

@media (md-up) {
  .buttonNav {
    justify-content: center;
  }
  .navbarLinkItemLink {
    width: 250px;
  }
}

.navbarLinkItemLinkSignUp {
  composes: buttonFilled from 'assets/css/elements/newButtons.css';
}

.socialLinksWrapper {
  margin-top: 1rem;
}

@media (md-up) {
  .socialLinksWrapper {
    margin-top: var(--space-sm);
  }
}

.socialLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.625rem;
  padding-left: 0;
  list-style: none;
  color: var(--color-new-green);
}

.socialLink {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.socialLink:hover {
  text-decoration: underline;
}

.socialIconWrapper {
  display: grid;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--color-white);
  border-radius: 50%;
}

@media (md-up) {
  .socialIconWrapper {
    width: 3.125rem;
    height: 3.125rem;
  }
}

.socialIcon {
  margin: auto;
  width: 1rem;
  height: 1rem;
}

@media (md-up) {
  .socialIcon {
    width: 1.25rem;
    height: 1.25rem;
  }
}

/* @media (lg-up) {
  .socialIcon {
    width: 2.4rem;
  }
} */
